import React, { useState, useEffect } from "react";
import styles from "../styles/styles";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { server } from "../server";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Header from "../components/Layout/Header";
import { RxCross1 } from "react-icons/rx";


const SendSamples = () => {
    const { id } = useParams();
    const [allDJobs, setAllDJobs] = useState([]);
    const { user } = useSelector((state) => state.user);
    const [open2, setOpen2] = useState(false);
    const [samples, setSamples] =useState("");
    const navigate = useNavigate();
    

    useEffect(() => {
        axios.get(`${server}/djob/get-all-djobs`, {withCredentials: true}).then((res) => {
        setAllDJobs(res.data.djobs);
       
       })
     }, []); 

    
       

        const data = allDJobs && allDJobs.find((i) => i._id === id);
       
        const sendSamplesHandler = async (e) => {

            e.preventDefault();
            await axios
              .put(`${server}/djob/sending-samples/${data._id}`,{

                samples: samples,
                workerName: user.name,
                jobID : data._id,

              },
              {withCredentials: true})
              .then((res) => {
               toast.success("Sample Link Sent!");
               navigate('/profile');
               window.location.reload();
               });
          };

         
      
      return (
        <div className="bg-white">
          {data?.workStatus === "Completed" ? (
          <>
          <div className="w-full py-5 px-10">
            <h1 className="text-[30px] text-[500]">Job Approved and Closed</h1>
            <p>This job is now completed and clossed by the client.</p>
          </div>
        </>
        ): (
          <>
          {data ? (
                <div className={`${styles.section} w-[90%] 800px:w-[80%]`}>
                <div className="w-full py-5">
                  
                  <div className="block w-full 800px:flex">
                        <div className="w-full 800px:w-[50%] pt-5">
                            <h4 className="pb-1 font-[600] text-[25px]">
                           Job Sample Submission
                            </h4>

                            <h4 className="py-2 text-[16px] mb-3">
                            Be sure you have given this job your best shot before submiting the samples link. Remember, "First impression matters alot." Goodluck!
                            </h4>

                            <h2 className="text-[18px] font-[600]">Job Details:</h2>
                            <div className="flex">
                             <h5 className={`${styles.productDiscountPrice} font-[300]`}>
                            <small className="text-[15px] text-[blue]">Payment:</small> ${data.taskPayment} 
                           </h5>
                            </div>

                            <div className="flex">
                             <h5 className="text-[14px] font-[400]">
                            <small className="text-[15px] text-[blue]">Required Skills:</small> {data.requiredSkills}
                            </h5>
                            </div>

                            <div className="flex">
                             <h5 className={`${styles.productDiscountPrice} font-[300]`}>
                            <small className="text-[15px] text-[blue]">Job Duration:</small> {data.taskDuration}
                             <small className="text-[13px]"> {data.durationMode}</small> 
                            </h5>
                            </div>

                            <div className="flex">
                             <h5 className={`${styles.productDiscountPrice} font-[300]`}>
                            <small className="text-[15px] text-[blue]">Location:</small> {data.taskEnvironment}
                            </h5>
                            </div>

                            <div className="flex">
                             <h5 className={`${styles.productDiscountPrice} font-[300]`}>
                            <small className="text-[15px] text-[blue]">Opening:</small> {data.targetedCountry}
                            </h5>
                            </div>

                            <div className="flex">
                             <h5 className={`${styles.productDiscountPrice} font-[300]`}>
                            <small className="text-[15px] text-[blue]">Listed On:</small> {data?.createdAt.slice(0, 10)}
                            </h5>
                            </div>

                            <div className="flex">
                             <h5 className={`${styles.productDiscountPrice} font-[300]`}>
                            <small className="text-[15px] text-[red]">Deadline:</small> {data?.deadLine}
                            </h5>
                            </div>

                           <br />


                            {user && user?.staffRole !== "noneStaff" && (
                              <div>
                                <div className="800px:w-[50%] text-[16px] text-[blue]">
                                <h5>Worker: {user?.name}</h5>
                                </div> 
                                <br />
                                      
                                <button
                                className={`${styles.button} !h-[42px] text-white`}
                                onClick={() => setOpen2(true)}
                               >
                                Add link
                                </button>
                              </div>
                            )}

            {open2 && (
            <div className="w-full fixed h-screen top-0 left-0 bg-[#00000031] z-[9999] flex items-center justify-center">
            <div className="w-[50%] min-h-[40vh] bg-white rounded shadow p-4">
            <div className="flex justify-end w-full">
              <RxCross1 size={25} onClick={() => setOpen2(false)} />
            </div>
             
                <div className="w-full 800px:flex block pb-3">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">Sample(s) Link</label>
                  <input
                    type="text"
                    className={`${styles.input} !bg-[#F6F6F5] !w-[95%] mb-4 800px:mb-0`}
                    required
                    value={samples}
                    onChange={(e) => setSamples(e.target.value)}
                  />
                </div>
               </div>
               <br />
              <button
                type="submit"
                className={`block ${styles.button} !bg-[green] text-white !h-[42px] mt-4 text-[18px]`}
                onClick={sendSamplesHandler}
              >
                Send
              </button>
            </div>
          </div>
          )} 
                         </div>
                        </div>
                      
                    </div>
                </div>
            ) : <div className="w-full py-5 px-10">
                <h1 className="text-[25px] text-[500]">Loading!</h1>
                <p>It may take few minutes.</p>
                </div>}
          </>
        )}
      </div>
           );

        }


        const SendSamplesPage = () => {
            return (
                <>
                <Header />
                <SendSamples />
                </>
                
            )
        }

export default SendSamplesPage;

