export const server = "https://totlesoftpay-backend.vercel.app/api/v2";

//  http://localhost:8000/api/v2
// https://totlesoftpay-backend.vercel.app/api/v2

//grey #F6F6F5
// default youtube video ID kGKQF5krIdM

// totlesoft yellow color code :  bg-[#c89b2a]

// Personal Paypal test
// email: sb-h2j1p30305767@personal.example.com
// p/w: *b9$7Jc}

// business test 
// email: sb-8nd1l30302791@business.example.com
// p/w: 9uTcW>1B

